export default [
  {
    name: 'redphoenix',
    type: 'Staff',
    uuid: 'cb46ad54-8c78-4dbc-a8ed-4e3185b2b3ff',
  },
  {
    name: 'SabreCat',
    type: 'Staff',
    uuid: '7f14ed62-5408-4e1b-be83-ada62d504931',
  },
  {
    name: 'viirus',
    type: 'Staff',
    uuid: 'a327d7e0-1c2e-41be-9193-7b30b484413f',
  },
  {
    name: 'beffymaroo',
    type: 'Staff',
    uuid: '9fe7183a-4b79-4c15-9629-a1aee3873390',
  },
  {
    name: 'Apollo',
    type: 'Staff',
    uuid: '9b2f4123-f749-4f74-85e2-ce31ce778435',
  },
  {
    name: 'Piyo',
    type: 'Staff',
    uuid: '61b2c855-0a30-444c-bcc6-1cac876460b0',
  },
  {
    name: 'heyeilatan',
    type: 'Staff',
    uuid: 'f4e5c6da-0617-48bf-b3bd-9f97636774a8',
  },
];
